import BaseApi from './base';
const baseApi = new BaseApi('/api/basic');

const requestBaseApi = new BaseApi('/api/request');
/**
 * 查询列表
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function request(params) {
	return requestBaseApi.post(params, 'current');
}
/**
 * 新增basic,根据type分为customer,cp terms,port remarks
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function addBasicInfo(params) {
	return baseApi.post(params, 'add');
}

/**
 * 编辑basic,根据type分为customer,cp terms,port remarks
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function updateBasicInfo(params) {
	return baseApi.post(params, 'update');
}

/**
 * 查询基本信息
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function getBasicPageInfo(params) {
	return baseApi.get(params, 'pageInfo');
}
/**
 * 删除基本信息
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function deleteBasicPageInfo(params) {
	return baseApi.get(params, 'delete');
}

/**
 * 查询基本信息
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function getBasicPageList(params) {
	return baseApi.get(params, 'pageList');
}
/**
 * 新增航线接口
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function routeSave(params) {
	return baseApi.post(params, 'addLine');
}
/**
 * 更新航线接口
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function routeUpdate(params) {
	return baseApi.post(params, 'updateLine');
}
/**
 * 新增航线接口
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function getRouteLine(params) {
	return baseApi.get(params, 'selectLine');
}
export function delRouteLine(params) {
	return baseApi.get(params, 'deleteLine');
}

/**
 * 新增航线接口
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function getRouteLineInfo(params) {
	return baseApi.get(params, 'selectLineInfo');
}

/**
 * 查询日报
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function getMasterReport(params) {
	return baseApi.get(params, 'selectMasterReport');
}
/**
 * 更新日报
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function updateMasterReport(params) {
	return baseApi.post(params, 'updateMasterReport');
}

/**
 * 添加日报
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function addMasterReport(params) {
	return baseApi.post(params, 'addMasterReport');
}
/**
 * 删除日报
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
 export function deleteMasterReport(params) {
	return baseApi.get(params, 'deleteMasterReport');
}
