// import simplifyParams from '@/utils/simplify_params';
import qs from 'querystring';
import Vue from 'vue';
import CryptoJS from 'crypto-js';

class Base {
	constructor(controller) {
		this.controller = controller;
	}
	/**
	 * 加密
	 * @param word
	 * @returns {*}
	 */
	encrypt(word) {
		let key = CryptoJS.enc.Utf8.parse('g8uyfdsoi4fd5f21');
		let srcs = CryptoJS.enc.Utf8.parse(word);
		let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
		// return encrypted.toString();
		return encrypted.ciphertext.toString();
	}

	/**
	 * 解密
	 * @param word
	 * @returns {*}
	 */
	decrypt(word) {
		let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
		let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);

		let key = CryptoJS.enc.Utf8.parse('g8uyfdsoi4fd5f21');
		let decrypt = CryptoJS.AES.decrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
		return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	}
	/**
	 * 查询列表
	 * 关键字方法，用于搜索列表
	 * @param {Object} params 请求参数
	 * @return {Object|null} 返回结果，错误则返回null
	 */
	search(params) {
		return this.get(params, 'pageList');
	}

	/**
	 * 新增 post
	 * @param {Object} params 参数
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	add(params) {
		return this.post(params, 'add');
	}

	/**
	 * 修改 put
	 * @param {Object} params 参数
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	update(params) {
		return this.put(params, 'update');
	}

	/**
	 * 获取详情 get
	 * @param  {String|Number} id 产品id
	 * @param {Object} params 参数 可以为空
	 * @return {Object|null} 返回结果，错误则返回null
	 */
	detail(id, params = {}) {
		return this.get(params, id);
	}

	/**
	 * 保存操作
	 * @param {Object} params 参数
	 * @param {String} addMethod 新增方法 默认update
	 * @param {String} updateMethod 修改方法 默认add
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	save(params, addMethod = 'add', updateMethod = 'update') {
		if (!params) return new Error('保存信息错误');
		return params.id ? this[updateMethod](params) : this[addMethod](params);
	}

	/**
	 * 查询列表
	 * @param {Object} params 请求参数
	 * @param {String} method 方法名称
	 * @return {Object|null} 返回结果，错误则返回null
	 */
	get(params, method) {
		const errorTip = '网络出错，请稍后再试';
		// 清洗空数据
		// params = simplifyParams(params, false);
		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: 'get',
			params,
		}).then((res) => {
			if (res?.code === 200) {
				// let data = this.decrypt(res.data);
				return res.data;
			}
			return null;
		});
		// .catch((e) => {
		// 	return new Error(e?.message || errorTip);
		// });
	}

	/**
	 * post请求提交，适用于新增，修改
	 * @param {Object} params 参数
	 * @param {String} method 方法名称
	 * @param {String} type 请求类型，post put delete
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	post(params, method, type = 'post') {
		// 清洗空数据
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		const errorTip = '操作失败，请稍后再试';

		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: type,
			data: params,
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				if(res?.ais){
					return res;
				}
				if(res?.data){
					return res;
				}
				return null;
			})
			.catch((e) => {
				return null;
			});
	}
	exportPost(params, method, type = 'post') {
		// 清洗空数据
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		const errorTip = '操作失败，请稍后再试';
		if (type == 'post') {
			return Vue.axios({
				url: `${this.controller}/${method}`,
				method: type,
				responseType: 'blob',
				data: params,
				headers: {
					'content-type': 'application/json',
				},
			})
				.then((res) => {
					if (res) {
						return res;
					}
					return new Error(res?.message || errorTip);
				})
				.catch((e) => {
					return new Error(e?.message || errorTip);
				});
		}
		else {
			return Vue.axios({
				url: `${this.controller}/${method}`,
				method: type,
				responseType: 'blob',
				params,
				headers: {
					'content-type': 'application/json',
				},
			})
				.then((res) => {
					if (res) {
						return res;
					}
					return new Error(res?.message || errorTip);
				})
				.catch((e) => {
					return new Error(e?.message || errorTip);
				});
		}

	}
	loginPost(params, method, type = 'post') {
		// 清洗空数据
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		const errorTip = '操作失败，请稍后再试';

		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: type,
			data: params,
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}

	/**
	 * put请求提交，适用于修改
	 * @param {Object} params 参数
	 * @param {String} method 方法名称
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	put(params, method) {
		return this.post(params, method, 'put');
	}

	/**
	 * put请求提交，适用于排序
	 * @param {Object} params 参数
	 * @param {String} method 方法名称
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	putSort(params, method, isSimplifyParams = true) {
		const errorTip = '操作失败，请稍后再试';

		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: 'put',
			params,
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}
	/**
	 * 删除
	 * @param {Object} params 请求参数
	 * @param {String} method 方法名称
	 * @return {Object|null} 返回结果，错误则返回null
	 */
	delete(params, method) {
		const errorTip = '操作失败，请稍后再试';
		// 清洗空数据
		// params = simplifyParams(params, false);
		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: 'delete',
			params,
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}

	/**
	 * post 搜索
	 * @param {*} params 参数
	 */
	postSearch(params, method, isSimplifyParams = true) {
		const errorTip = '网络出错，请稍后再试';
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: 'post',
			data: qs.stringify(params),
			// headers: { 'content-type': 'application/x-www-form-urlencoded' },
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}
	/**
	 * delete body方式删除
	 */
	deleteBody(params, method) {
		// 清洗空数据
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		const errorTip = '操作失败，请稍后再试';

		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: 'post',
			data: params,
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}

	/**
	 * upData请求提交，适用于上传文件
	 * @param {Object} params 参数
	 * @param {String} method 方法名称
	 * @param {String} type 请求类型，post put delete
	 * @return {Object|Error} 操作结果，失败返回error信息
	 */
	upData(params, method, type = 'post') {
		// 清洗空数据
		// if (isSimplifyParams) {
		// 	params = simplifyParams(params, false);
		// }
		const errorTip = '操作失败，请稍后再试';

		return Vue.axios({
			url: `${this.controller}/${method}`,
			method: type,
			data: params,
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
			.then((res) => {
				if (res?.code === 200) {
					return res;
				}
				return new Error(res?.message || errorTip);
			})
			.catch((e) => {
				return new Error(e?.message || errorTip);
			});
	}
}

export default Base;
